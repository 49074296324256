import React, { FunctionComponent } from 'react';

import './board-review.scss';
import ShortTable from '../short-table/short-table';
import AuctionBox from '../auction-box/auction-box';
import TrickHistory from '../trick-history/trick-history';
import BoardStats from '../board-stats/board-stats';
import { IRootState } from '../../store/_root.reducer';
import { connect } from 'react-redux';
import { classNames } from '../../_utils/helper';

export interface IBoardReviewProps extends StateProps, DispatchProps {
    isStandAlone?: boolean;
}

const BoardReview: FunctionComponent<IBoardReviewProps> = ({ game, app, isStandAlone }) => {
    if (!game.foreignBoardReviewData) {
        return null;
    }
    const { playerData, seatData, contract, gamePhase, cards, vulnerability } = game.foreignBoardReviewData;
    let { showAuctionBox } = game.foreignBoardReviewData;

    const {  urlParams } = app;
    if (urlParams.objective) {
        showAuctionBox = false;
    }

    return (
        <section className={classNames('BoardReview', isStandAlone && 'stand-alone')}>
            <div className="short-table">
                <ShortTable seatData={seatData} contract={contract} gamePhase={gamePhase} cards={cards} layout={app.layout} />
            </div>
            <div className="auctionBox-trickHistory">
                {playerData.map((_playerData, index) => (
                    <div key={`auctionBox-trickHistory-column-${index}`} className="auctionBox-trickHistory-column">
                        <h3>{urlParams.objective ? 'Trick by Trick' : index === 0 ? 'Your Auction' : 'Recommended'}</h3>
                        <AuctionBox
                            foreignBoardData={{
                                seatData,
                                vulnerability,
                                showAuctionBox,
                                auction: _playerData.auction
                            }}
                        />
                        <TrickHistory playerData={_playerData} seatData={seatData} />
                    </div>
                ))}
            </div>
            <div className="board-stats">
                <BoardStats />
            </div>
        </section>
    );
};

const mapStateToProps = ({ game, app }: IRootState) => ({
    game,
    app
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BoardReview);
