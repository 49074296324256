import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IGameResultsPairV2, IGameResultsTravelerV2 } from '../../_models/models';
import { IRootState } from '../../store/_root.reducer';

import './game-results.scss';
import { contractSuits, suits } from '../board-stats/board-stats';
import { suitArray } from '../../../game-engine-interface/game-engine-helper';
import { classNames } from '../../_utils/helper';
import BoardReview from '../board-review/board-review';
import { IOutputState, loadBoardReview } from '../../store/output.reducer';
import { setForeignBoardReviewData } from '../../store/game.reducer';

export interface IGameResultsProps extends StateProps, DispatchProps {
    test?: string;
}

const GameResults: FunctionComponent<IGameResultsProps> = props => {
    const { t } = useTranslation();
    const { game, children, loadBoardReview, setForeignBoardReviewData, app } = props;
    const { urlParams } = app;
    const { gameResultsV2: gameResults } = game;
    const [showScores, setShowScores] = useState<boolean>(true);
    const [showPair, setShowPair] = useState<IGameResultsPairV2 | undefined>(undefined);
    const [showBoard, setShowBoard] = useState<IGameResultsTravelerV2['bn'] | undefined>(undefined);
    const [showBoardReview, setShowBoardReview] = useState<IOutputState['loadBoardReview'] | undefined>(undefined);
    const [boardSelectOpened, setBoardSelectOpened] = useState(false);
    const [comparisonSelectOpened, setComparisonSelectOpened] = useState(false);
    // for going back
    const [lastPair, setLastPair] = useState<IGameResultsPairV2 | undefined>(undefined);

    const d = [
        t('boardStats.bridgePosition.n'),
        t('boardStats.bridgePosition.e'),
        t('boardStats.bridgePosition.s'),
        t('boardStats.bridgePosition.w')
    ];

    const objective: string | undefined = urlParams.objective

    const { Pairs, Scoring } = gameResults ?? {};

    const travelerKeys: (keyof IGameResultsTravelerV2)[] = objective ? ['name', 'l', 'r', 's', 'reMP', 'ruMP']
                                                                     : Scoring === 'IMP' ? ['dir', 'name', 'c', 'd', 'l', 'r', 's', 'reIMP', 'ruIMP']
                                                                                          :['dir', 'name', 'c', 'd', 'l', 'r', 's', 'reMP', 'ruMP'] ;

    const handleShowPair = (pairId: IGameResultsPairV2['uuid']) => () => {
        // console.log('pairId', pairId);
        if (!gameResults) {
            return null;
        }

        const { Pairs } = gameResults;
        //console.log('handleShowPair: ', gameResults);
        setShowScores(false);
        setShowBoard(undefined);

        const selectedPair = Pairs !== undefined ? Pairs.find(pair => pair.uuid === pairId) : undefined;

        if (selectedPair) {
            setShowPair(selectedPair);
        }
    };

    const handleShowBoard = (bn: IGameResultsTravelerV2['bn']) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setLastPair(showPair);
        setShowBoard(bn);
    };

    const handleShowBoardReview = (bn: IGameResultsTravelerV2['bn'], p_uuid: string) => (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation();
        if (p_uuid) {
            const boardReviewData: IOutputState['loadBoardReview'] = {
                bn,
                pair: p_uuid,
                game: game.gameResultsV2 ? game.gameResultsV2.Game : game.gameResults ? game.gameResults.Game : ''
            };
            setShowBoardReview(boardReviewData);
            loadBoardReview(boardReviewData);
        }
    };

    const handleChangeBoardReview = (partial: Partial<IOutputState['loadBoardReview']>) => {
        const boardReviewData = showBoardReview ? { ...showBoardReview, ...partial } : showBoardReview;
        setShowBoardReview(boardReviewData);
        setBoardSelectOpened(false);
        setComparisonSelectOpened(false);
        loadBoardReview(boardReviewData);
    };

    const handleHideBoardReview = () => {
        setShowBoardReview(undefined);
        setForeignBoardReviewData(undefined);
    };

    const goBackToScores = () => {
        setShowScores(true);
        setShowBoard(undefined);
        setShowPair(undefined);
        setLastPair(undefined);
    };

    const goBackToPair = () => {
        setShowScores(false);
        setShowBoard(undefined);
        setShowPair(lastPair);
        setLastPair(undefined);
    };

    const formatValues = (key: keyof IGameResultsPairV2 | keyof IGameResultsTravelerV2, value: any) => {
        //(key);
        switch (key) {
            case 'd':
                return value >= 0 ? d[value] : '-';
            case 'ruMP': {
                return (value as number).toFixed(2) + '%';
            }
            case 'reMP': {
                return (value as number).toFixed(2) + '%';
            }
            case 'ruIMP':
                return (value as number).toFixed(0);
            case 'reIMP':
                return (value as number).toFixed(0);
            case 'r': {
                return value === 0 ? '=' : value > 0 ? `+` + value : value;
            }
            default:
                return value;
        }
    };

    const getSuit = (suit: string) => {
        const suits: { [key: string]: string } = {
            s: 'spades',
            d: 'diamonds',
            c: 'clubs',
            h: 'hearts'
        };

        if (suits[suit]) {
            return <span className={`suit ${suits[suit]}`} />;
        }
        return suit;
    };

    const renderTraveler = (key: keyof IGameResultsTravelerV2, traveler: any) => {
        switch (key) {
            case 'name':
                return (
                    <span className="link" onClick={handleShowPair(traveler.uuid)}>
                        {traveler.name}
                    </span>
                );
            case 'c':
                return (
                    <div
                        className={classNames(
                            'contract suit',
                            traveler.c >= 10 ? contractSuits[`${traveler.c}`.substring(1, 2)] : 'passOut'
                        )}
                    >
                        {`${traveler.c}`.substring(1, 2) === '4' && (
                            <div className="notrump">{contractSuits[`${traveler.c}`.substring(1, 2)]}</div>
                        )}
                        <div className="level">{traveler.c > 0 ? `${traveler.c}`.substring(0, 1) : 'Pass out'}</div>
                        <div className="x">&nbsp;{' XX'.substring(0, traveler.x)}</div>
                    </div>
                );
            case 'l':
                return (
                    <div
                        className={classNames(
                            'suit',
                            traveler.l >= 0 ? suits[suitArray[traveler.l].substring(0, 1) as keyof typeof suits] : ''
                        )}
                    >
                        <span className="rank">{traveler.l >= 0 ? suitArray[traveler.l].substring(1) : '-'}</span>
                    </div>
                );
            default:
                return formatValues(key, traveler[key]);
        }
    };

    const sortByScoring = (a: IGameResultsPairV2, b: IGameResultsPairV2) => {
        if (!gameResults) {
            return 0;
        }
        const { Scoring, ShowRanks } = gameResults;
        if (!ShowRanks) {
            return a.me ? 1 : 0;
        }
        if (Scoring === 'IMP') {
            return b.ruIMP - a.ruIMP;
        }
        return b.ruMP - a.ruMP;
    };

    const sortByResultScoring = (a: IGameResultsTravelerV2, b: IGameResultsTravelerV2) => {
        if (!gameResults) {
            return 0;
        }
        const { Scoring, ShowRanks } = gameResults;
        if (!ShowRanks) {
            return a.me ? 1 : 0;
        }
        if (Scoring === 'IMP') {
            return b.reIMP - a.reIMP;
        }
        return b.reMP - a.reMP;
    };

    const renderGameResultsScores = () => {
        if (!gameResults) {
            return null;
        }

        const { Rounds, BoardsPerRound } = gameResults;
        const { Pairs, Scoring, Movement, ShowRanks } = gameResults;
        const pairKeys: (keyof IGameResultsPairV2)[] =  objective ? ['ruMP', 'ruMPP'] :  Scoring === 'IMP' ? ['ruIMP', 'masterPoints'] : ['ruMP', 'ruMPP', 'masterPoints'];

        const boardspergame: number =
            gameResults.BoardsPerRound && gameResults.Rounds
                ? gameResults.BoardsPerRound * gameResults.Rounds
                : gameResults.BoardsPerRound
                ? gameResults.BoardsPerRound
                : 0;

        const pairs: IGameResultsPairV2[][] =
            Movement === 'Mitchell' ? [Pairs.filter(pair => pair.dir === 'ns'), Pairs.filter(pair => pair.dir === 'ew')] : [Pairs];
        //console.log(pairs);
        return pairs.map((_pairs, index) => (
            <div key={`_pairs-${index}`} className="gameResultsScores">
                {Movement === 'Mitchell' && <h2>{_pairs[0]?.dir}</h2>}
                <table>
                    <thead>
                        <tr>
                            <th className={ShowRanks ? '' : 'hide'}>{t(`gameResults.scores.thead.rank`)}</th>
                            <th>{t(`gameResults.scores.thead.pair`)}</th>
                            {pairKeys.map((key, thIndex) => (
                                <th key={`th-${thIndex}`}>{t(`gameResults.scores.thead.${key}`)}</th>
                            ))}
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {_pairs.sort(sortByScoring).map((pair: IGameResultsPairV2, trIndex) => (
                            <tr
                                key={`tr-${trIndex}`}
                                className={pair.me ? 'isMe' : pair.uuid === app.urlParams['pn'] ? 'isMe' : 'isNotMe'}
                                onClick={handleShowPair(pair.uuid)}
                            >
                                <td className={ShowRanks ? '' : 'hide'}>{Scoring === 'IMP' ? pair.rIMP : pair.rMP}</td>
                                <td className="pair">
                                    {boardspergame > pair.traveler.length ? '* ' : ''}
                                    {pair.name}
                                </td>
                                {pairKeys.map((key, tdIndex) => (
                                    <td key={`tr-${trIndex}-td-${tdIndex}`} className={key}>
                                        {formatValues(key, pair[key])}
                                    </td>
                                ))}
                                <td className="action">
                                    <button onClick={handleShowPair(pair.uuid)}>{t('gameResults.scores.showPair')}</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        ));
    };

    const renderPairThead = (travelerKeys: Array<keyof IGameResultsTravelerV2>, table: 'pair' | 'board') => {
        return (
            <thead>
                <tr>
                    {objective &&
                        (
                            <th key={`th-objective-1`} colSpan={2}>
                                {t(`gameResults.pair.thead.objective`)} {objective} {t(`gameResults.pair.thead.tricks_full`)}
                            </th>
                        )
                    }
                    {travelerKeys.map((key, thIndex) => {
                        switch (key) {
                            case 'reMP':
                                return (
                                    <th key={`th-${thIndex}-1`} colSpan={1}>
                                        {t(`gameResults.pair.thead.result`)}
                                    </th>
                                );
                            case 'ruMP':
                                return (
                                    <th key={`th-${thIndex}-1`} colSpan={1}>
                                        {t(`gameResults.pair.thead.running`)}
                                    </th>
                                );
                            case 'c':
                                return (
                                    <th key={`th-${thIndex}-1`} colSpan={4}>
                                        {t(`gameResults.pair.thead.summary`)}
                                    </th>
                                );
                            default:
                                if (!['d', 'l', 'r', 'reIMP', 'ruIMP'].includes(key)) {
                                    return <th key={`th-${thIndex}-1`} />;
                                }
                                return null;
                        }
                    })}
                </tr>
                <tr>
                    {travelerKeys.map((key, thIndex) => (
                        <th key={`th-${thIndex}-2`}>{t(`gameResults.${table}.thead.${key}`)}</th>
                    ))}
                </tr>
            </thead>
        );
    };

    const RenderGameResultsPair = () => {
        if (!showPair) {
            return null;
        }

  //      const travelerKeys: Array<keyof IGameResultsTravelerV2> = ['bn', 'dir', 'c', 'd', 'l', 'r', 's', 'reMP', 'reIMP', 'ruMP', 'ruIMP'];
        const travelerKeys: (keyof IGameResultsTravelerV2)[] = objective ? ['bn', 'l', 'r', 's', 'reMP', 'ruMP']
                                                                         : Scoring === 'IMP' ? ['bn', 'dir', 'c', 'd', 'l', 'r', 's', 'reIMP', 'ruIMP']
                                                                                             :['bn', 'dir', 'c', 'd', 'l', 'r', 's', 'reMP', 'ruMP'] ;
        // console.log('showPair', showPair);

        return (
            <>
                <div className="header">
                    <button className="back" onClick={showBoardReview ? handleHideBoardReview : goBackToScores} />
                    <h2>
                        {showBoardReview && <RenderSelectBoard bn={showBoardReview.bn} />}
                        {showPair.name}
                        {showBoardReview && <RenderComparisonSelect />}
                    </h2>
                </div>
                <table className={classNames('gameResultsPair v2', showBoardReview && 'hidden')}>
                    {renderPairThead(travelerKeys, 'pair')}
                    <tbody>
                        {(showPair.traveler || [])
                            .sort((a, b) => {
                                return a.bn - b.bn;
                            })
                            .map((traveler, trIndex) => (
                                <tr
                                    key={`tr-${trIndex}`}
                                    className={false ? 'isMe' : 'isNotMe'}
                                    onClick={handleShowBoardReview(traveler.bn, showPair?.uuid)}
                                >
                                    {travelerKeys.map((key, tdIndex) => (
                                        <td key={`tr-${trIndex}-td-${tdIndex}`} className={key}>
                                            {renderTraveler(key, { ...traveler })}
                                        </td>
                                    ))}
                                    <td className="action">
                                        <button onClick={handleShowBoard(traveler.bn)}>
                                            {t('gameResults.pair.showBoard')} {traveler.bn}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </>
        );
    };

    const RenderGameResultsBoard = () => {
        if (!gameResults) {
            return null;
        }

        //console.log('Rendering board #', showBoard);
        return (
            <>
                <div className="header ">
                    <button className="back" onClick={showBoardReview ? handleHideBoardReview : goBackToPair} />
                    <h2>
                        {showBoard && showBoardReview && <RenderSelectBoard bn={showBoardReview.bn ?? showBoard} />}
                        {showBoardReview && Pairs
                            ? getTravellerByUuid(showBoardReview.pair, Pairs)?.name ?? '-'
                            : t(`gameResults.board.headline`, { boardNumber: showBoard })}
                        {showBoardReview && <RenderComparisonSelect />}
                    </h2>
                </div>
                {showBoard && <RenderGameResultsBoardTable boardNumber={showBoard} />}
            </>
        );
    };

    const RenderGameResultsBoardTable = ({
        boardNumber,
        selectionData
    }: {
        boardNumber: IGameResultsTravelerV2['bn'];
        selectionData?: {
            uuid?: IGameResultsTravelerV2['uuid'];
        };
    }) => {
        const travelers: any[] = (Pairs ?? []).reduce((prev: IGameResultsTravelerV2[], next) => {
            const { traveler, name, uuid, me } = next;

            return [
                ...prev,
                ...traveler
                    .filter(travelerData => travelerData.bn === boardNumber)
                    .map(travelerData => ({
                        ...travelerData,
                        name,
                        uuid,
                        me
                    }))
            ];
        }, []);
        return (
            <div>
                {['ns'].map(dir => (
                    <table key={dir} className={classNames('gameResultsBoards v2', showBoardReview && 'hidden')}>
                        {renderPairThead(travelerKeys, 'board')}
                        <tbody>
                            {travelers
                                .filter(traveler => traveler.dir === dir)
                                .sort(sortByResultScoring)
                                .map((traveler, trIndex) => (
                                    <tr
                                        key={`${dir}-tr-${trIndex}`}
                                        className={classNames(
                                            selectionData?.uuid === traveler.uuid && `selected`,
                                            traveler.me ? 'isMe' : traveler.uuid === app.urlParams['pn'] ? 'isMe' : 'isNotMe'
                                        )}
                                        onClick={
                                            selectionData
                                                ? comparisonSelectOpened
                                                    ? () => handleChangeBoardReview({ comparisonUuid: traveler.uuid })
                                                    : () => setComparisonSelectOpened(true)
                                                : handleShowBoardReview(traveler.bn, traveler.uuid)
                                        }
                                    >
                                        {travelerKeys.map((key, tdIndex) => (
                                            <td key={`${dir}-tr-${trIndex}-td-${tdIndex}`} className={key}>
                                                {renderTraveler(key, traveler)}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                ))}
            </div>
        );
    };

    const RenderSelectBoard = ({ bn }: { bn: IGameResultsTravelerV2['bn'] }) => {
        return (
            <div className={classNames('select-board', boardSelectOpened && 'opened')}>
                {(showPair?.traveler || [])
                    .sort((a, b) => {
                        return a.bn - b.bn;
                    })
                    .map((traveler, trIndex) =>
                        traveler.bn === bn ? (
                            <div className="current-board" onClick={() => setBoardSelectOpened(!boardSelectOpened)}>
                                Board {traveler.bn}
                            </div>
                        ) : (
                            <div onClick={() => handleChangeBoardReview({ bn: traveler.bn })}>Board {traveler.bn}</div>
                        )
                    )}
            </div>
        );
    };

    const RenderComparisonSelect = () => {
        // console.log('showBoardReview?.comparisonUuid', showBoardReview?.comparisonUuid);
        return showBoardReview?.bn ? (
            <div className={classNames('select-comparison', comparisonSelectOpened && 'opened')}>
                <RenderGameResultsBoardTable
                    boardNumber={showBoardReview.bn}
                    selectionData={{
                        // uuid: showBoardReview?.comparisonUuid ?? Pairs?.[0]?.uuid
                        uuid: showBoardReview?.comparisonUuid ?? (showPair?.uuid === Pairs?.[0]?.uuid ? Pairs?.[1]?.uuid : Pairs?.[0]?.uuid)
                    }}
                />
            </div>
        ) : null;
    };

    return (
        <section className="GameResults">
            {showScores && renderGameResultsScores()}
            {!!showPair && !showBoard && <RenderGameResultsPair />}
            {!!showBoard && <RenderGameResultsBoard />}
            {showBoardReview && (
                <div className="showBoardReview">
                    <BoardReview isStandAlone />
                </div>
            )}
            {children}
        </section>
    );
};

const getTravellerByUuid = (uuid: IGameResultsPairV2['uuid'], pairs: IGameResultsPairV2[]): IGameResultsPairV2 | undefined => {
    return pairs.find(pair => pair.uuid === uuid);
};

const mapStateToProps = ({ game, app, output }: IRootState) => ({
    game,
    app
});

const mapDispatchToProps = {
    loadBoardReview,
    setForeignBoardReviewData
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GameResults);
