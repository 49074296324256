import { ReactElement } from 'react';
import { IAuctionBoxProps } from '../_elements/auction-box/auction-box';

interface ISubSet {
    suits: ReactElement;
    king: string;
    queen: string;
    jack: string;
}

export interface ISet {
    ranks: ReactElement;
    biddings: ReactElement;
    diamonds: ISubSet;
    hearts: ISubSet;
    spades: ISubSet;
    clubs: ISubSet;
}

export enum BridgePosition {
    north = 'north',
    east = 'east',
    south = 'south',
    west = 'west'
}

export enum DisplayPosition {
    top = 'top',
    right = 'right',
    bottom = 'bottom',
    left = 'left'
}

export enum Vulnerability {
    none = 'none',
    ns = 'ns',
    ew = 'ew',
    both = 'both'
}

export const suitLookup: Record<string, number> = {
    c: 0,
    d: 1,
    h: 2,
    s: 3,
    n: 4
};

export const suitReverseLookup: Record<number, string> = {
    0: 'c',
    1: 'd',
    2: 'h',
    3: 's',
    4: 'nt'
};

export enum Suit {
    clubs = 'clubs',
    hearts = 'hearts',
    spades = 'spades',
    diamonds = 'diamonds'
}

export enum Rank {
    ace = 'ace',
    king = 'king',
    queen = 'queen',
    jack = 'jack',
    ten = 'ten',
    nine = 'nine',
    eight = 'eight',
    seven = 'seven',
    six = 'six',
    five = 'five',
    four = 'four',
    three = 'three',
    two = 'two'
}

export const suits: Suit[] = [Suit.spades, Suit.hearts, Suit.diamonds, Suit.clubs];
export const ranks: Rank[] = [
    Rank.ace,
    Rank.king,
    Rank.queen,
    Rank.jack,
    Rank.ten,
    Rank.nine,
    Rank.eight,
    Rank.seven,
    Rank.six,
    Rank.five,
    Rank.four,
    Rank.three,
    Rank.two
];

export type Level = 'zero' | 'one' | 'two' | 'three' | 'four' | 'five' | 'six' | 'seven';
export type Strain = Suit | 'noTrump' | 'double' | 'redouble' | 'pass' | 'auctionAlert' | 'auctionStop' | 'undefined' | 'q';
export const levels: Level[] = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven'];
export const strains: Strain[] = [Suit.clubs, Suit.diamonds, Suit.hearts, Suit.spades, 'noTrump', 'pass', 'double', 'redouble'];
export interface IBid {
    level: Level;
    strain: Strain;
    alertMessage?: string;
    explanation?: {} | undefined;
}

export type ICardPosition = 'center' | 'hand' | 'trick' | 'deck';

// TODO: With the current model will fall short when we want to add competitive games, where the client gets only the hand it can see.
//  When a card is played you will not have bridgePosition for it. Right now when a play is made I also send the BridgePosition that made it.

export interface ICard {
    id: string;
    suit: Suit;
    rank: Rank;
    bridgePosition?: BridgePosition;
    position: ICardPosition;
    raised?: boolean;
}

export interface ICards {
    [cardId: string]: ICard;
}

interface IBaseSeatData {
    dummyCards: number;
    isActive: boolean;
    isDealer: boolean;
    isDeclarer: boolean;
    isDummy: boolean;
    isHighlighted: boolean;
    isInteractive: boolean;
    isMe: boolean;
    isVisible: boolean;
    isVulnerable: boolean;
    player?: string;
}

export interface ISeatData extends IBaseSeatData {
    bridgePosition: BridgePosition;
    displayPosition: DisplayPosition;
}

export const initialBaseSeatData: IBaseSeatData = {
    dummyCards: 0,
    isActive: false,
    isDealer: false,
    isDeclarer: false,
    isDummy: false,
    isHighlighted: false,
    isInteractive: false,
    isMe: false,
    isVisible: false,
    isVulnerable: false,
    player: undefined
};

export enum GamePhase {
    PRE = 'PRE',
    DEAL = 'DEAL',
    BID = 'BID',
    BID_PLAY = 'BID_PLAY',
    PLAY = 'PLAY',
    END = 'END'
}

export interface IModal {
    body?: Array<JSX.Element>;
    buttons?: IModalButton[];
    cancelButtonLabel?: string | JSX.Element;
    className?: string;
    header?: string | JSX.Element;
    id?: string;
    isBlockUI?: boolean;
    noClickOutside?: boolean;
    noHeaderClose?: boolean;
    noCancel?: boolean;
    onCancel?: () => void;
    showForeignBoardReviewData?: boolean;
    fullSize?: boolean;
}

export interface IModalButton {
    label?: string | JSX.Element;
    className?: string;
    primary?: boolean;
    onClick?: (id: IModal['id']) => void;
    externalTo?: string;
    to?: string;
    target?: string;
}

export interface ChatEntry {
    sender: string;
    timestamp: string;
    message: string;
    isSystemMessage?: boolean;
    isDirectorMessage?: boolean;
}

export type GeneralLayout = 'default' | 'v_impaired';

export type HandLayout = 'straight' | 'fan' | 'columns' | 'stacked';

export interface ITableResult {
    boardNumber?: string;
    contract?: string;
    declarer?: string;
    ewPoints?: number;
    lead?: ICard['id'];
    nsPoints?: number;
    result?: string;
}

export interface IGameResultsTraveler {
    boardindex: number;
    boardnumber: number;
    contract: string;
    declarer: 'n' | 's' | 'e' | 'w';
    dir: 'ns' | 'ew';
    lead: string;
    runningIMP: number;
    runningMP: number;
    resultIMP: number;
    resultMP: number;
    score: number;
    tricksResult: string;
    UUID: string;
    vs: string;
    vsPairId: IGameResultsPair['pairId'];
    pair: string;
}

export interface IGameResultsPair {
    pairId: string;
    p1: string;
    p2: string;
    rankMP: string;
    rankIMP: string;
    runningMP: number;
    runningIMP: number;
    traveler: IGameResultsTraveler[];
    dir: IGameResultsTraveler['dir'];
}

export interface IGameResultsTravelerV2 {
    bi: number;
    bn: number;
    c: number;
    x: number;
    d: number;
    dir: 'ns' | 'ew';
    l: number;
    ruIMP: number;
    ruMP: number;
    reIMP: number;
    reMP: number;
    s: number;
    r: string;
    uuid: string;
    name: string;
    me: boolean;
}

export interface IGameResultsPairV2 {
    uuid: string;
    name: string;
    rMP: string;
    rIMP: string;
    ruMP: number;
    ruMPP: number;
    ruIMP: number;
    me: boolean;
    traveler: IGameResultsTravelerV2[];
    dir: 'ns' | 'ew' | undefined;
    masterPoints?: number;
    p1?: { pid: string };
    p2?: { pid: string };
}

export interface IGameResults {
    BoardsPerRound?: number;
    EventName?: string;
    Game: string;
    HostName?: string;
    Pairs: IGameResultsPair[];
    Movement: 'Mitchell' | 'Howell';
    Rounds?: number;
    Scoring: 'MP' | 'IMP';
    ShowRanks: boolean;
    TimeStamp: number;
    UUID: string;
    myPairId?: IGameResultsPair['pairId'];
}

export interface IGameResultsV2 extends Omit<IGameResults, 'Pairs' | 'myPairId'> {
    Pairs: IGameResultsPairV2[];
}

export interface IPoll {
    id: string;
    question: string;
    answers: string[];
}

export type BoardStatKeys = {
    r: number;
    s: number;
    c: number;
    d: number;
    x: number;
    l: number;
};

export type BoardStats = {
    result: number;
    count: number;
    key: BoardStatKeys;
};

export type CardId =
    | 'c2'
    | 'c3'
    | 'c4'
    | 'c5'
    | 'c6'
    | 'c7'
    | 'c8'
    | 'c9'
    | 'ca'
    | 'cj'
    | 'ck'
    | 'cq'
    | 'ct'
    | 'd2'
    | 'd3'
    | 'd4'
    | 'd5'
    | 'd6'
    | 'd7'
    | 'd8'
    | 'd9'
    | 'da'
    | 'dj'
    | 'dk'
    | 'dq'
    | 'dt'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'h7'
    | 'h8'
    | 'h9'
    | 'ha'
    | 'hj'
    | 'hk'
    | 'hq'
    | 'ht'
    | 's2'
    | 's3'
    | 's4'
    | 's5'
    | 's6'
    | 's7'
    | 's8'
    | 's9'
    | 'sa'
    | 'sj'
    | 'sk'
    | 'sq'
    | 'st';
